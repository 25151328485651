export const GET_ALL_USER_HAS_PERMISSIONS = "GET_ALL_USER_HAS_PERMISSIONS";
export const GET_ALL_USER_HAS_PERMISSIONS_SUCCESS =
    "GET_ALL_USER_HAS_PERMISSIONS_SUCCESS";
export const CREATE_USER_HAS_PERMISSIONS = "CREATE_USER_HAS_PERMISSIONS";
export const UPDATE_USER_HAS_PERMISSIONS = "UPDATE_USER_HAS_PERMISSIONS";
export const DELETE_USER_HAS_PERMISSIONS = "DELETE_USER_HAS_PERMISSIONS";
export const SET_ENABLE_FOR_USER = "SET_ENABLE_FOR_USER";
export const SET_PERMISSION_FOR_USER = "SET_PERMISSION_FOR_USER";
export const GET_USER_JUST_CREATE = "GET_USER_JUST_CREATE";

export const GET_USER_BY_RECRUITMENT = "GET_USER_BY_RECRUITMENT";
export const GET_USER_BY_RECRUITMENT_SUCCESS =
    "GET_USER_BY_RECRUITMENT_SUCCESS";

export const GET_USER_STATUS_VIEWED_BY_RECRUITMENT =
    "GET_USER_STATUS_VIEWED_BY_RECRUITMENT";
