export const GET_ADS_ALL = "GET_ADS_ALL";
export const GET_ADS_ALL_SUCCESS = "GET_ADS_ALL_SUCCESS";

export const GET_ADS_ALL_BY_LOCATION = "GET_ADS_ALL_BY_LOCATION";
export const GET_ADS_ALL_BY_LOCATION_SUCCESS =
    "GET_ADS_ALL_BY_LOCATION_SUCCESS";

export const GET_ADS_BY_ID = "GET_ADS_BY_ID";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";

export const CREATE_ADS = "CREATE_ADS";
export const UPDATE_ADS = "UPDATE_ADS";
export const REMOVE_ADS = "REMOVE_ADS";
