import { createTheme } from "@material-ui/core";

export const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: "#EA4E1B",
            main: "#EA4E1B",
            dark: "#EA4E1B",
        },
        success: {
            light: "#4BB543",
            main: "#4BB543",
            dark: "#4BB543",
        },
        warning: {
            light: "#f88f01",
            main: "#f88f01",
            dark: "#f88f01",
        },
        error: {
            light: "#c72c41",
            main: "#c72c41",
            dark: "#c72c41",
        },
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "SF-Pro-Text",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});
