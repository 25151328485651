export const GET_PROVINCES = "GET_PROVINCES";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";
export const GET_SUGGESTION_PROVINCES = "GET_SUGGESTION_PROVINCES";
export const GET_SUGGESTION_PROVINCES_SUCCESS =
    "GET_SUGGESTION_PROVINCES_SUCCESS";
export const UPDATE_PROVINCE = "UPDATE_PROVINCE";
export const DELETE_PROVINCE = "UPDATE_PROVINCE";

export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const CLEAR_DISTRICTS = "CLEAR_DISTRICTS";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";

export const GET_WARDS = "GET_WARDS";
export const CLEAR_WARDS = "CLEAR_WARDS";
export const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS";
export const UPDATE_WARD = "UPDATE_WARD";
export const CREATE_WARD = "CREATE_WARD";

export const GET_STREETS_BY_WARD_ID = "GET_STREETS";
export const GET_STREETS_SUCCESS = "GET_STREETS_SUCCESS";
export const GET_STREET_BY_ID = "GET_STREET_BY_ID";
export const GET_STREET_SUCCESS = "GET_STREET_SUCCESS";
export const UPDATE_STREET = "UPDATE_STREET";
export const CREATE_STREET = "CREATE_STREET";
export const DELETE_STREET = "DELETE_STREET";
