import { gql } from "apollo-boost";
import { IGetToken } from "common/formatTypes";
import { axiosJSON, graphQLCommon } from "common/utils/api";

export const getAccessToken = async (payload: IGetToken) => {
    const { data, loginMethod } = payload;
    try {
        const url = `${process.env.REACT_APP_GRAPHQL_URL}/auth/firebase/${loginMethod}`;
        const response = await axiosJSON({
            url,
            data: JSON.stringify(data),
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
        // store.dispatch(setLoading(false));

        return response;
    } catch (error) {
        console.error(error);
        return {};
    }
};

const REFRESH_TOKEN = gql`
    query Query {
        refreshToken {
            accessToken
            refreshToken
            userInfo {
                _id
                birthday
                gender
                urlAvt {
                    small
                    medium
                    default
                }
                firstName
                lastName
                displayName
                username
                email
                phoneNumber
                permission
                point
                postedJob
                isVerified
                company {
                    _id
                    isIdentified
                    name
                    logo {
                        default
                        medium
                        small
                    }
                }
                code
                rate
            }
        }
    }
`;

export const refreshToken = async () => {
    const result = await graphQLCommon(REFRESH_TOKEN, {});
    return result;
};
