export const GET_ALL_PAGES = "GET_ALL_PAGES";
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS";

export const UPDATE_PAGES = "UPDATE_PAGES";
export const CREATE_PAGES = "CREATE_PAGES";
export const REMOVE_PAGES = "REMOVE_PAGES";

// SEO
export const GET_SEO = "GET_SEO";
export const GET_SEO_SUCCESS = "GET_SEO_SUCCESS";
export const UPDATE_SEO = "UPDATE_SEO";

// CONTENT WEBSITE
export const GET_CONTENT_WEBSITE_BY_ID = "GET_CONTENT_WEBSITE_BY_ID";
export const GET_CONTENT_WEBSITE_BY_ID_SUCCESS =
    "GET_CONTENT_WEBSITE_BY_ID_SUCCESS";
export const UPDATE_CONTENT_WEBSITE = "UPDATE_CONTENT_WEBSITE";
