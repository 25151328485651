import { lazy } from "react";
import { IRootState, IRoutes } from "common/formatTypes";
import { PATH } from "constants/routes";
import { t } from "language";
import * as icons from "designs/Icons/Drawer";
import { useSelector } from "react-redux";

const Overview = lazy(() => import("pages/dashboard/Overview"));
export const overviewRoutes: IRoutes = {
    name: t("drawer.overview"),
    path: PATH.OVERVIEW,
    exact: true,
    Component: Overview,
    isPrivate: true,
    Icon: icons.Overview,
    permission: "MANAGER",
    type: "overview",
};
