import * as services from "services/auth";
import { call, put } from "redux-saga/effects";
import { loginSuccess } from "redux/actions/auth";
import { setLoading } from "redux/actions/common";
import { showNotification } from "redux/actions/notification";
import { IGraphQLResponse, ILoginInput } from "common/formatTypes";

export function* login(payload: any) {
    const variables: ILoginInput = {
        user: payload.payload.user,
    };
    const permission = payload.payload.permission;
    yield put(setLoading(true));
    const response: IGraphQLResponse = yield call(
        services.loginWithPhoneNumber,
        variables,
    );
    yield put(setLoading(false));
    const { login: result } = response?.data || {};

    if (result) {
        if (
            "ADMIN" === result.userInfo.permission ||
            "MANAGER" === result.userInfo.permission
        ) {
            showNotification({
                type: "success",
                title: "Thông báo",
                message: "Đăng nhập thành công!",
            });
            yield put(loginSuccess(result));
        } else {
            showNotification({
                type: "error",
                title: "Thông báo",
                message: "Tài khoản không phải là tài khoản người dùng !",
            });
        }
    }
}
