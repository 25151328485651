//CAREER_COUNSELING
export const GET_ALL_CAREER_COUNSELING = "GET_ALL_CAREER_COUNSELING";
export const GET_ALL_CAREER_COUNSELING_SUCCESS =
    "GET_ALL_CAREER_COUNSELING_SUCCESS";
export const CREATE_CAREER_COUNSELING = "CREATE_CAREER_COUNSELING";
export const UPDATE_CAREER_COUNSELING = "UPDATE_CAREER_COUNSELING";
export const DELETE_CAREER_COUNSELING = "DELETE_CAREER_COUNSELING";

//CATEGORY
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

//TAG
export const GET_ALL_TAG = "GET_ALL_TAG";
export const GET_ALL_TAG_SUCCESS = "GET_ALL_TAG_SUCCESS";
export const CREATE_TAG = "CREATE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
